@import '~video-react/dist/video-react.css';

body {
  font-family: Roboto Light, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
}

.video-react .video-react-control-bar {
  position: inherit !important;
}

html {
  background: rgb(245, 245, 245);
}

header img {
  height: 65px;
  margin: 12px 14px;
  cursor: pointer;
}

.headerContainer {
  position: fixed;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  width:100%;
  background-color: white;
  z-index: 100;
  text-align: center;
  border-bottom: 1px solid #c7c7c7;
}

.menu {
  position: relative;
  max-width: 1220px;
  padding: 0 60px;
  margin: 0 auto;
}

.navigation {
  display: block;
  margin-top: 40px;
  margin-bottom: 20px;
}

.clickHere {
  cursor: pointer;
  text-decoration: none;
  color: #546e7a;
}

.navigation a,
.navigation span {
  text-decoration: none;
  display: inline-block;
  margin: 0px 30px;
  white-space: nowrap;
  position: relative;
  color: #546e7a;
  padding: 4px;
  font-size: 1em;
  transition: 0.3s;
  border-bottom: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.navigation a:hover,
.navigation span:hover {
  color: #263136;
  border-bottom: 1px solid #263136;
  transition: 0.5s;
  cursor: pointer;
}

.homePageContainer {
  position: relative;
  width: 100%;
}

.playerContainer {
  text-align: center;
  
}

.innerWrapper {
  width: 100%;
  max-height: 1800px;
  overflow: hidden;
  position: relative;
}

.homeText h1 {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
}

.video-react .video-react-control-bar {
  background-color: none;
}

/*-------------------*/

.homeText {
  width:100%;
  color: #546e7a;
  line-height: 25px;
  font-size: 14px;
  min-height: 365px;
  margin-top: 35px;
  margin-bottom: 70px;
}

.homeTextInnerWrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.homeTextInner {
  margin: 0px 50px;
}


.customButton {
  display: inline-block;
  text-align: center;
  border: 1px solid #f0f0f0;
  margin: 0px 20px;
  cursor: pointer;
  transition: 0.3s;
}

.customButton:hover {
  border: 1px solid #d4d4d4;
}

.buttonsWrapper {
  text-align: center;
  margin-top: 60px;
  margin-bottom:60px;
}

.customButton img {
  width: 100px;
  padding: 20px 100px;
}

.customButton div {
  text-align: center;
  font-size: 25px;
  color: #1e1e1c;
  padding-bottom: 20px;
} 

.instafeed__item__overlay {
  display: none;
}

.elementWrapper {
  overflow: hidden;
}

.cityContentInner, .schoolsContentInner, .homestayContentInner {
  transition: 0.5s;
  padding-bottom: 50px;
}

html {
  overflow-y: scroll;
}

.sloganWrapper {
  text-align: center;
  padding: 40px 0px;
}

.footerWrapper {
  background-color: #161d24;
  color: #fff;
  text-align: center;
  margin: 0;
}

.footerElement {
  display: inline-block;
  margin: 20px 100px 30px 100px;

  vertical-align: top;
  line-height: 22px;
}

/*otstup playera sverhu*/
.playerContainer {
  margin-top: 85px;
}

.SchoolsCumstomButton {
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 250px;
  padding: 10px 0px;
  font-weight: 600;
}

.SchoolsCumstomButton2 {
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 250px;
  padding: 10px 0px;
  font-weight: 600;
  text-decoration: none;
  color: #546e7a;
}

.schoolsCustomButtonsWrapper {
  margin-top: 40px;
  text-align: center;
  padding-bottom: 20px;
}

.frenchSchoolsWrapper, .englishSchoolsWrapper, .frenchPrivSchoolsWrapper {
  overflow: hidden;
  text-align: center;
}


.frenchSchoolsWrapper a, .frenchSchoolsWrapper div {
  transition: 1s;
}
.englishSchoolsWrapper a, .englishSchoolsWrapper div {
  transition: 1s;
}

.frenchPrivSchoolsWrapper a, .frenchPrivSchoolsWrapper div {
  transition: 1s;
}

.frenchSchoolsWrapper a, .englishSchoolsWrapper a, .frenchPrivSchoolsWrapper a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  margin: 15px;
  border: 1px solid #afafaf;
  transition: 0.3s;
  font-size: 12px;
  width: 300px;
  padding: 50px 0px;
}

.frenchSchoolsWrapper a:hover {
  opacity: 0.8;
}

.englishSchoolsWrapper a:hover {
  opacity: 0.8;
}

.frenchPrivSchoolsWrapper a:hover {
  opacity: 0.8;
}

.SchoolsBlockWrapper {
  margin-bottom: 60px;
}

/*Schools background images*/

/* FR */

.antoineLabelle {
  background-image: url('/images/mini/Antoine.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.pierre {
  background-image: url('/images/mini/laporte1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.internationale {
  background-image: url('/images/mini/Inter.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.prelude {
  background-image: url('/images/mini/prelude1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.trois {
  background-image: url('/images/mini/trois1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.bruno {
  background-image: url('/images/mini/bruno1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.louis {
  background-image: url('/images/mini/louis1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}
.edmond {
  background-image: url('/images/mini/edmond1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

/* FR PRIV */

.letendre {
  background-image: url('/images/mini/letendre1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center; 
}

.anne {
  background-image: url('/images/mini/anne1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center; 
}

.regina {
  background-image: url('/images/mini/regina1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center; 
}

.citroyen {
  background-image: url('/images/mini/citoyen1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center; 
}

/* EN */

.heritage {
  background-image: url('/images/mini/heritage1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.stLambert {
  background-image: url('/images/mini/lambert1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.centennial {
  background-image: url('/images/mini/cent1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.westmount {
  background-image: url('/images/mini/westmount1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.lester {
  background-image: url('/images/mini/lester1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.lauren {
  background-image: url('/images/mini/lauren1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.laval {
  background-image: url('/images/mini/liberty1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.rosemere {
  background-image: url('/images/mini/rosemere1.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.schoolDetailsBlock p {
  line-height: 15px;
}

.schoolDetailsBlock {
  display: inline-block;
  max-width: 350px;
  vertical-align: top;
  margin-right: 45px;
  margin-bottom: 50px;
  max-height: 160px;
}

.homeTextActualText img {
  width: 48%;
  float: right;
  margin-right: 10px;
}

.detailsHeader {
  width: 100%;
  height: 390px;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding-top: 30px;
}
.subBlock1 {
  width: 33%;
  float: left;
}
.subBlock2 {
  width: 66%;
  float: left;
}

.subBlock2 img {
  border: 1px solid #eaeaea;
}

.homeTextActualText {
  width: fit-content;
  margin: 0 auto;
}

.footerElement img {
  width: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.followUsHeader {
  margin-left: 20px;
}

.sloganWrapper h2 {
  font-weight: 300;
}

.sloganWrapper span {
  font-weight: 900;
}

.montrealInfoBlock {
  display: inline-block;
  width: 45%;
  margin-right: 30px;
  vertical-align: top;
  margin-top: 13px;
}

.homeTextInner .montrealPic, .homeTextInner .homestayPic {
  vertical-align: top;
  display: inline-block;
  width: 45%;
  margin: 30px;
  border: 1px solid #eaeaea;
}

.homestayInfoBlockWrapper {
  display: inline-block;
  width: 40%;
  margin-left: 30px;
  vertical-align: top;
  margin-top: 13px;
}

#app {
  background-color: #f1f1f1;
}

.homePageContainer {
  border-bottom: 1px solid #dcdcdc;
}

.homestayInfoBlockWrapper .galleryButton {
  float: right;
}

.customItemList {
  margin: 0px 0px;
  padding-left: 0px;
}

.customItemListStay {
  margin: 0px 0px;
  padding-left: 0px;
  text-align: center;
}

.customItemListStay li {
  width: 20%;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  padding: 30px 25px;
  font-weight: 600;
  color: #546e7a;
  line-height: 22px;
}

.customItemList li {
  width: 25%;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  padding: 30px 0px;
  font-weight: 600;
  color: #546e7a;
}

#testPic {
  border: 0px;
}

.customItemListWrapperInner {
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.customItemListWrapper {
  width: 100%;
  background-color: #eaeaea;
  border-top: 1px solid #dcdcdc;
}

.registerWrapperInfo {
  display: inline-block;
  width: 60%;
  margin-right: 20px;
}

.galleryButtonHome {
  cursor: pointer;
    padding: 10px 40px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    width: fit-content;
    text-decoration: none;
    color: #546e7a;
    display: inline-block;
    margin-top: 15px;
    margin-right: 30px;
    font-weight: 600;
    background-color: #eaeaea;
}

.galleryButton {
  cursor: pointer;
    padding: 10px 40px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    width: fit-content;
    text-decoration: none;
    color: #546e7a;
    display: inline-block;
    margin-top: 25px;
    margin-right: 30px;
    font-weight: 600;
    background-color: #eaeaea;
}

.registrationPic {
  vertical-align: top;
  display: inline-block;
  width: 30%;
  margin: 30px;
}

/* new */

.galleryWrapper {
  margin-bottom: 60px;
}

.galleryWrapperInner {
  overflow: hidden;
  text-align: center;
}

.galleryWrapperInner span {
  transition: 1s;
}

.galleryWrapperInner span {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  margin: 15px;
  border: 1px solid #afafaf;
  transition: 0.3s;
  font-size: 12px;
  width: 300px;
  padding: 120px 0px;
}

.galleryWrapperInner span:hover {
  opacity: 0.8;
}

/* images from the gallery */

.image1 {
  
  background-image: url('/images/gallery/mini/German students at Mtl Canadiens hockey game mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image2 {
  background-image: url('/images/gallery/mini/Annika rolling stick in snow with maple syrup mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image3 {
  background-image: url('/images/gallery/mini/Annika with host sisters mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image4 {
  background-image: url('/images/gallery/mini/Apple picking mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image5 {
  background-image: url('/images/gallery/mini/Bjorn on tee mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image6 {
  background-image: url("/images/gallery/mini/Bjorn with team - We're number 1 mini.jpg");
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image7 {
  background-image: url('/images/gallery/mini/Downtown MTL shopping mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image8 {
  background-image: url('/images/gallery/mini/Franzi playing hockey mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image9 {
  background-image: url('/images/gallery/mini/Franzi with husky friends mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image10 {
  background-image: url('/images/gallery/mini/German girls downtown Mtl mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image11 {
  background-image: url('/images/gallery/mini/German student - Nature mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image12 {
  background-image: url('/images/gallery/mini/Quebec winter carnival mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image13 {
  background-image: url('/images/gallery/mini/Greta in Mtl Chinatown mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image14 {
  background-image: url('/images/gallery/mini/halloween - with French school Mtl  friends mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image15 {
  background-image: url('/images/gallery/mini/Halloween with friends mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image16 {
  background-image: url('/images/gallery/mini/Hanging with Canadian friends mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image17 {
  background-image: url('/images/gallery/mini/Hiking Mt Tremblant mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image18 {
  background-image: url('/images/gallery/mini/Joel with Indians mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image19 {
  background-image: url('/images/gallery/mini/Lake in the Fall mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image20 {
  background-image: url('/images/gallery/mini/Laura from Brazil mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image21 {
  background-image: url('/images/gallery/mini/Lounging in Laurentians mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image22 {
  background-image: url('/images/gallery/mini/Marie Therese - skiing Winter Break mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image23 {
  background-image: url('/images/gallery/mini/Meret with friends in Old Montreal mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image24 {
  background-image: url('/images/gallery/mini/Montreal Moose mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image25 {
  background-image: url('/images/gallery/mini/New York trip mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image26 {
  background-image: url("/images/gallery/mini/Nick & little host brother hockey mini.jpg");
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image27 {
  background-image: url('/images/gallery/mini/Old port montreal mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image28 {
  background-image: url('/images/gallery/mini/Pablo - school band mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image29 {
  background-image: url('/images/gallery/mini/pumpkin picking mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image30 {
  background-image: url('/images/gallery/mini/Quebec city trip mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image31 {
  background-image: url('/images/gallery/mini/Acting in school play mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image32 {
  background-image: url('/images/gallery/mini/raika - volley team mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image33 {
  background-image: url('/images/gallery/mini/School Bus mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image34 {
  background-image: url('/images/gallery/mini/School trip to CoCo Beach FI mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image35 {
  background-image: url('/images/gallery/mini/School trip to New York mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image36 {
  background-image: url('/images/gallery/mini/Ski - Le Massif, Quebec mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image37 {
  background-image: url('/images/gallery/mini/Snowmobile fun mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image38 {
  background-image: url('/images/gallery/mini/student host fam winter mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image39 {
  background-image: url('/images/gallery/mini/Student ice fishing mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

.image40 {
  background-image: url('/images/gallery/mini/Yannick in soccer team mini.jpg');
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;  
}

/* Modal */

.modalWrapper {
  position: fixed;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 3;
}

.homeText2 {
  width:100%;
  color: #546e7a;
  line-height: 25px;
  font-size: 14px;
  min-height: 365px;
  margin-bottom: 70px;
}



